<template>
  <div class="settings-clinical-users">
    <vue-good-table
      :ref="tableReference"
      :total-rows="totalRecords"
      :columns="columns"
      :rows="formatedClinicalUsers"
      :is-loading="isLoading"
      mode="remote"
      style-class="vgt-table"
      :max-height="maxHeight"
      :fixed-header="true"
      :pagination-options="{
        enabled: true
      }"
      :search-options="{
        enabled: true,
        externalQuery: settingsSearch
      }"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-search="onSearchTermChange"
      @on-cell-click="customOpenEdit"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <UserWithAvatar
          v-if="props.column.label == $t('settingsClinicalUsersTable.name')"
          :first-name="props.row.first_name"
          :last-name="props.row.last_name"
        />
        <span v-else-if="props.column.label == $t('settingsClinicalUsersTable.status')">
          <PatientUserStatusButton
            :account-data="props.row.account_information"
            :user-data="props.row"
          />
        </span>
        <span v-else-if="props.column.label == $t('settingsClinicalUsersTable.roles')">
          <span v-html="props.formattedRow[props.column.field]" />
        </span>
        <span v-else-if="props.column.label == $t('settingsClinicalUsersTable.locations')">
          <span v-html="props.formattedRow[props.column.field]" />
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div
        v-show="isLoading"
        slot="loadingContent"
      >
        <LoadingSpinner />
      </div>
      <div
        v-show="!isLoading"
        slot="emptystate"
      >
        {{ $t('noClinicalUsersFound') }}
      </div>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <PaginationFooter
          v-show="paginationEnabled"
          :total="props.total"
          :server-params="serverParams"
          :per-page-changed="props.perPageChanged"
          :page-changed="onPageChange"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import moment from 'moment'
import { VueGoodTable } from 'vue-good-table'
import { mapGetters } from 'vuex'
import { SettingsClinicalUsers } from '@/mixins/TableColumnsHelper'
import { SettingsCrud } from '@/mixins/SettingsCrud'
import { GET_CLINICAL_USERS } from '@/store/modules/clinicalUsers/constants'
import { GET_LOCATIONS } from '@/store/modules/locations/constants'
import { STATUS_STRINGS, STATUS_INVITATION_SENT } from '@/data/userStatusValues'
import Roles from '@/data/roles'
import PatientUserStatusButton from '@/components/admin/settings/extra/patientUsers/PatientUserStatusButton'
import UserWithAvatar from '@/components/admin/settings/common/UserWithAvatar'
import { RemoteTable } from '@/mixins/remoteTables/RemoteTable'
import PaginationFooter from '@/components/common/PaginationFooter'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'

export default {
  name: 'SettingsClinicalUsers',
  components: {
    VueGoodTable,
    PatientUserStatusButton,
    PaginationFooter,
    LoadingSpinner,
    UserWithAvatar
  },
  mixins: [SettingsClinicalUsers, SettingsCrud, RemoteTable],
  data () {
    return {
      tableReference: 'settingsClinicalUsers',
      tableMaxHeight: 154,
      availableRoles: Roles,
      defaultSortOrder: 'asc',
      defaultSortField: 'full_name',
      action: GET_CLINICAL_USERS
    }
  },
  computed: {
    ...mapGetters({
      clinicalUsers: 'getClinicalUsers',
      locations: 'getLocations'
    }),
    formatedClinicalUsers () {
      return this.clinicalUsers.map(cu => {
        const locationString = this.getLocationsString(cu.locations)
        const roleString = this.getRolesString(cu.roles)
        const statusString = this.getStatusString(cu)
        return { ...cu, locationString, roleString, statusString }
      })
    }
  },
  created () {
    this.loadItems()
    this.getData(GET_LOCATIONS)
    componentRefreshSingleton.addComponentToRefresh(this.$options.name, this)
  },
  destroyed () {
    componentRefreshSingleton.removeComponentToRefresh(this.$options.name)
  },
  methods: {
    customOpenEdit (params) {
      const notAllowed = [this.$t('settingsClinicalUsersTable.status')]
      if (!notAllowed.includes(params.column.label)) {
        this.openEdit(params)
      }
    },
    refreshView () {
      this.loadItems(true)
    },
    getStatusString (data) {
      const invalid = !data.status || !STATUS_STRINGS[data.status]
      let statusString = invalid ? null : STATUS_STRINGS[data.status]
      if (parseInt(data.status) === STATUS_INVITATION_SENT) {
        const now = moment().utc()
        const invitedSince = moment.utc(data.status_changed_at)
        const daysAgo = now.diff(invitedSince, 'days')
        if (daysAgo > 0) {
          statusString += ` ${daysAgo}d ago`
        }
      }
      return statusString
    },
    getRolesString (roles) {
      return this.availableRoles.filter(r => roles.includes(`${r.id}`)).reduce((carry, role) => {
        return `${carry} ${role.text} <br>`
      }, '')
    },
    getLocationsString (locations) {
      return this.locations.filter(l => locations.includes(l.id)).reduce((carry, location) => `${carry} ${location.name} <br>`, '')
    }
  }
}
</script>
